import { important, px } from "csx";
import { style } from "typestyle";
import * as Style from "../../styles";

export const component = style({
  height: px(40),
  whiteSpace: "nowrap",
  $nest: {
    "&:focus": {
      outline: "none",
    },
  },
  textDecoration: "none",
  color: Style.colors.primary,
  fontWeight: 600,
  borderColor: Style.colors.primary,
});

export const colored = style({
  backgroundColor: "transparent",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.primary,
      color: Style.colors.white,
      border: `1px solid ${Style.colors.primary}`,
      transition: "0.3s"
    },
  },
});

export const rounded = style({
  borderRadius: important(px(32)),
});

export const secondary = style({
  color: Style.colors.secondary,
  borderColor: Style.colors.secondary,
});
