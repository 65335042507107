import * as React from "react";
import * as style from "./About.style";
import { connect } from "react-redux";
import { Product } from "shopify-buy";
import { RootState } from "../../store/reducers";
import { IKImage } from "imagekitio-react";
export interface StateProps {
  products: Product[] | null;
}

const AboutInternal: React.FC<StateProps> = ({ products }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.component}>
        <div className={style.title}>About Us</div>
        <div className={style.subTitleSection}>
          <div className={style.subTitle}>
            <div>Delicious, innovative</div>
            <div>yet simple food.</div>
          </div>
          <div className={style.subDetails}>
            {`Carey’s Fine Foods provides much of Baltimore with their tasty delights, whether it be a catered affair, 
gourmet meals for delivery or pick up and of course their famous Bourbon Pretzels! `}
          </div>
        </div>
        <div className={style.imageSection}>
          <IKImage
            path={`/carey-downey.jpg`}
            alt="carey and downey"
            className={style.careyImage}
          />
          <IKImage
            path={`/pretzels-beer.jpg`}
            alt="pretzels and beer"
            className={style.pretzelImage}
          />
        </div>
        <div className={style.aboutWrapper}>
          <div className={style.subDetails}>
            {
              "Carey & Downey’s Pretzels, a daily sellout are made with pretzel bites, spices & their family secrets!"
            }
          </div>
          <div className={style.subDetails}>
            Carey’s background in the food industry began with her grandparents,
            Edith and Charlie Harrington who were the original owners of
            Harrington’s grocery in Ruxton, MD - today known as Graul's.
          </div>
          <div className={style.subDetails}>
            Charlie Harrington instilled an exceptional work ethic in his
            granddaughter, Carey.
          </div>
          <div className={style.subDetails}>
            Family history &amp; this mother daughter duo’s hard work are the
            foundation of their catering &amp; fine foods business, now in its{" "}
            <b>20th</b> year!
          </div>
        </div>
        <div className={style.subDetails}>
          Carey Talucci <br />
          Downey Dupont
        </div>
      </div>
    </div>
  );
};

AboutInternal.displayName = "About";
export const About = connect((state: RootState) => {
  return {
    products: state.shopify.products,
  };
}, {})(AboutInternal);
