import MuiTextField from "@material-ui/core/TextField";
import * as React from "react";
import { classes } from "typestyle";
import * as style from "./TextInput.style";

type sizes = "small" | "medium";

export interface ComponentProps {
  /**
   * Optional className to apply to the MUI component
   */
  className?: string;
  /**
   * Optional className to apply to the component
   */
  wrapperClassName?: string;
  /**
   * current value
   */
  value: any;
  /**
   *
   */
  disabled?: boolean;
  /**
   * is there an error (makes outline red)
   */
  error?: boolean | string;
  /**
   * fires on input change
   */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * fires on blur
   */
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  /**
   * input type
   */
  type?: React.InputHTMLAttributes<unknown>["type"];
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * placeholder / label
   */
  placeholder?: string;
  size?: sizes;
}
/**
 * TextInput component
 * Standard MUI Text Input Wrapper
 */
const TextInput: React.FC<ComponentProps> = ({
  className,
  value,
  disabled,
  error,
  onChange,
  onBlur,
  type,
  inputRef,
  placeholder,
  size,
  wrapperClassName,
}) => {
  return (
    <div className={classes(style.component, wrapperClassName)}>
      <MuiTextField
        className={className}
        value={value}
        disabled={disabled}
        error={!!error}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        variant="outlined"
        inputRef={inputRef}
        label={placeholder}
        size={size}
        fullWidth
      />
    </div>
  );
};

TextInput.displayName = "TextInput";

export { TextInput };
