import React from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { classes } from "typestyle";
import * as style from "./Location.style";

interface ComponentProps {
className?: string;
}

const LocationInternal: React.FC<ComponentProps & {google: any}> = ({className, google}) => {

  const mapStyles = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "85%"
  };

  

  return (
    <>
    <div className={style.component}>
    <Map
          google={google}
          zoom={17}
          style={mapStyles}
          initialCenter={{lat: 38.68826, lng: -75.07533}}
        >
      <Marker position={{lat: 38.68826, lng: -75.07533}} />
    </Map>
    </div>
    </>
  );
};

export const Location = GoogleApiWrapper({apiKey: 'AIzaSyAGyMBD4JgDalhuBxb6Fjb0GR1sBnQg1DE'})(LocationInternal);