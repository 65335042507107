import * as React from "react";
import * as style from "./Product.style";
import { classes } from "typestyle";
import { buildClient, Cart, Product as ShopifyProduct } from "shopify-buy";
import { Button } from "../../Button";
import { TextInput } from "../../TextInput";
import * as Icons from "../../Icons";
import { IconButton } from "../../IconButton";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ActionTypes } from "../../../store/actions";

export interface StateProps {
  client: any;
  cart: Cart;
}

export interface RootProps {
  className?: string;
  product: ShopifyProduct;
}

export interface ComponentProps extends StateProps, RootProps {}

/**
 * Shopify Product component
 */
const ProductInternal: React.FC<ComponentProps> = ({
  className,
  product,
  client,
  cart,
}) => {
  const dispatch = useDispatch();
  const image = product.images.length ? product.images[0] : null;
  const [quantity, setQuantity] = React.useState<number>(1);
  const currentVariant = product.variants[0];
  const updateQuantity = (input: string) => {
    const num = Number.parseInt(input);
    if (!input || !isNaN(num)) {
      setQuantity(num);
    }
  };
  return (
    <div className={classes(className, style.component)}>
      {!!image && (
        <img
          src={image.src}
          alt={`${product.title} product shot`}
          className={style.image}
        />
      )}
      <div className={style.content}>
        <div className={style.title}>{product.title}</div>
        <div className={style.details}>
          <div className={style.productDescContainer}>
            <div className={style.description}>{product.description}</div>
            <div className={style.price}>${currentVariant.price.amount}</div>
          </div>
          <div className={style.quantitySection}>
            <TextInput
              wrapperClassName={style.quantityInput}
              type="number"
              value={quantity}
              onChange={(e) => updateQuantity(e.target.value)}
            />
            <div className={style.quantityButtons}>
              <IconButton
                onClick={() => setQuantity(quantity + 1)}
                size="small"
              >
                <Icons.AddBox />
              </IconButton>
              <IconButton
                disabled={quantity < 2}
                onClick={() => setQuantity(quantity - 1)}
                size="small"
              >
                <Icons.IndeterminateCheckBoxIcon />
              </IconButton>
            </div>
          </div>
          <div
            className={style.button}
            onClick={() =>
              addVariantToCart(
                currentVariant.id,
                quantity,
                client,
                cart,
                dispatch
              )
            }
          >
            Add to Cart
          </div>
        </div>
      </div>
    </div>
  );
};

const addVariantToCart = (
  variantId: string | number,
  quantity: number,
  client: any,
  cart: Cart,
  dispatch: any
) => {
  const lineItemsToAdd = [{ variantId, quantity }];
  if (cart && client) {
    client.checkout.addLineItems(cart.id, lineItemsToAdd).then((newCart) => {
      dispatch({ type: ActionTypes.CART_UPDATED, payload: { cart: newCart } });
      // toast.success(`Item${quantity > 1 ? "s" : ""} added to cart`);
    });
  }
};

ProductInternal.displayName = "Product";

export const Product = connect(
  (state: any) => ({
    client: state.shopify.client,
    cart: state.shopify.cart,
  }),
  {}
)(ProductInternal);
