import * as React from "react";
import { classes } from "typestyle";
import { IconButton } from "../IconButton";
import * as Icons from "../Icons";
import * as style from "./Header.style";
import Modal from "react-modal";
import { MobileHeader } from "./MobileHeader";
import { useDispatch, connect } from "react-redux";
import { Cart } from "shopify-buy";
import { ActionTypes } from "../../store/actions";

export interface StateProps {
  cart: Cart;
}

export interface ComponentProps extends StateProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}

/**
 * Header component
 * Page Header
 */
const HeaderInternal: React.FC<ComponentProps> = ({ className, cart }) => {
  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useDispatch();
  const cartCount = React.useMemo(() => {
    return !!cart
      ? cart.lineItems.reduce(
          (seed, lineItem) => (seed += lineItem.quantity),
          0
        )
      : 0;
  }, [cart]);

  return (
    <>
      <div className={classes(className, style.component)}>
        <IconButton
          onClick={() => setShowModal(true)}
          className={style.menuButton}
        >
          <Icons.Menu />
          <div className={style.menuText}> Menu</div>
        </IconButton>
        <div className={style.cart}>
          <IconButton
            className={style.menuButton}
            onClick={() =>
              dispatch({
                type: ActionTypes.SET_CART_OPEN,
                payload: {
                  isOpen: true,
                },
              })
            }
          >
            <Icons.ShoppingCart />
          </IconButton>
          {cartCount > 0 && <div className={style.cartCount}>{cartCount}</div>}
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={style.modalStyles}
        ariaHideApp={false}
      >
        <MobileHeader onClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

HeaderInternal.displayName = "HeaderInternal";
export const Header = connect(
  (state: any) => ({
    cart: state.shopify.cart,
  }),
  {}
)(HeaderInternal);
