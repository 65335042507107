import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {},
  media(
    { minWidth: Style.screensize.smMin },
    {
      backgroundColor: Style.colors.white,
      padding: `80px 60px`,
    }
  )
);

export const content = s(
  {
    backgroundColor: Style.colors.white,
    // borderRadius: 25,
    padding: `10px 20px`,
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      padding: `40px 30px`,
    }
  )
);

export const book = s(Style.text.largeHeader, {
  textDecoration: "none",
});

export const button = s({
  transform: "rotate(90deg)",
  position: "fixed",
  right: -55,
  top: "60%",
  bottom: "40%",
  height: 60,
  fontSize: 18,
  fontWeight: 600,
  backgroundColor: Style.colors.palletPurple,
  zIndex: 1000,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.black1,
    },
  },
});
