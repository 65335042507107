import { style as s, media } from "typestyle";
import * as Style from "../../styles";

const family = "poppins";

export const wrapper = s({
  backgroundColor: Style.colors.darkTranslucent20,
});

export const component = s(
  {
    marginTop: 85,
    marginLeft: 100,
    marginRight: 100,
    padding: 20,
  },
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      marginLeft: 5,
      marginRight: 5,
    }
  )
);

export const title = s(Style.text.extraLargeHeader, {
  paddingLeft: 20,
  paddingBottom: 10,
  color: Style.colors.primary,
  fontFamily: Style.text.font.main,
  marginBottom: 25,
  borderBottom: `1px solid ${Style.colors.darkTranslucent300}`,
  fontWeight: 300,
});

export const subTitleSection = s(
  {
    display: "flex",
    marginTop: 30,
    marginBottom: 30,
    alignItems: "center",
  },
  media(
    { maxWidth: Style.screensize.smMin },
    {
      flexDirection: "column",
    }
  )
);

export const subTitle = s(
  {
    fontFamily: family,
    textAlign: "center",
    fontSize: Style.text.fontSize.xxl,
    fontWeight: 200,
    color: "rgba(0,0,0,)",
    paddingRight: 15,
    borderRight: `1px solid ${Style.colors.darkTranslucent100}`,
    flex: 1,
  },
  media(
    { maxWidth: Style.screensize.mdMax },
    {
      fontSize: Style.text.fontSize.xl,
      padding: `15px 10px`,
    }
  ),
  media(
    { maxWidth: Style.screensize.smMin },
    {
      fontSize: Style.text.fontSize.xl,
      paddingBottom: 10,
      borderRight: "none",
      borderBottom: `1px solid ${Style.colors.darkTranslucent100}`,
    }
  )
);

export const subDetails = s(
  {
    fontFamily: family,
    fontSize: Style.text.fontSize.small,
    fontWeight: 300,
    color: Style.colors.black1,
    flex: 1,
    padding: `15px 10px`,
    maxWidth: 1000,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.smallMed,
      padding: `15px 30px`,
    }
  )
);

export const imageSection = s({
  // marginBottom: 200,
  marginBottom: 50,
  display: "flex",
  flex: 1,
  justifyContent: "center",
  textAlign: "center",
});

// export const careyWrapper = s(
//   {
//     width: 400,
//     height: 400,
//     borderLeft: `8px solid ${Style.colors.primary}`,
//     borderTop: `8px solid ${Style.colors.primary}`,
//   },
//   media(
//     { maxWidth: Style.screensize.mdMin },
//     {
//       width: 400,
//       height: 400,
//     }
//   )
// );

export const careyImage = s(
  {
    height: 400,
    borderLeft: `8px solid ${Style.colors.primary}`,
    borderTop: `8px solid ${Style.colors.primary}`,
  },
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      height: 400,
    }
  ),
  media(
    { maxWidth: Style.screensize.smMax },
    {
      height: 300,
    }
  )
);

export const pretzelImage = s(
  {
    borderLeft: `6px solid ${Style.colors.primary}`,
    borderTop: `6px solid ${Style.colors.primary}`,
    height: 250,
    marginLeft: -50,
    marginTop: 250,
  },
  media({ maxWidth: Style.screensize.mdMin }, {}),
  media(
    { maxWidth: Style.screensize.smMax },
    {
      display: "none",
    }
  )
);

export const aboutWrapper = s({
  display: "flex",
  flexDirection: "column",
});

export const content = s(
  {
    fontFamily: family,
    fontSize: Style.text.fontSize.medium,
    fontWeight: 400,
    color: Style.colors.black1,
    flex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.large,
    }
  )
);
