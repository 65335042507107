import * as React from "react";
import * as style from "./Cart.style";
import { IconButton } from "../../IconButton";
import * as Icons from "../../Icons";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";
import { ActionTypes } from "../../../store/actions";
import { CartItem } from "./CartItem";

export interface StateProps {
  client: any;
  cart: any;
  isOpen: boolean;
}

export interface RootProps {
  className?: string;
}

export interface ComponentProps extends StateProps, RootProps {}

/**
 * Shopify Product component
 */
const CartInternal: React.FC<ComponentProps> = ({
  className,
  client,
  cart,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const lineItems = cart ? cart.lineItems : [];
  const weight =
    lineItems.reduce((seed, lineItem) => seed + getWeight(lineItem), 0) + 6;
  const shippingPrice =
    weight <= 48
      ? 7.99
      : weight <= 96
      ? 11.99
      : weight <= 160
      ? 15.99
      : weight <= 240
      ? 19.99
      : 24.99;
  return isOpen && cart ? (
    <>
      <Modal
        isOpen={true}
        onRequestClose={() =>
          dispatch({
            type: ActionTypes.SET_CART_OPEN,
            payload: { isOpen: false },
          })
        }
        className={style.modal}
        style={{
          overlay: {
            zIndex: 1000,
          },
        }}
        ariaHideApp={false}
      >
        <div className={style.close}>
          <IconButton
            size="small"
            onClick={() =>
              dispatch({
                type: ActionTypes.SET_CART_OPEN,
                payload: { isOpen: false },
              })
            }
          >
            <Icons.Close />
          </IconButton>
        </div>
        <div className={style.component}>
          <div className={style.title}>Shopping Cart</div>
          {!!cart.lineItems.length ? (
            <>
              <div className={style.content}>
                <div className={style.items}>
                  <div className={style.labels}>
                    <div className={style.productLabel}>Product</div>
                    <div className={style.label}>Price</div>{" "}
                    <div className={style.label}>Quantity</div>{" "}
                    <div className={style.label}>Total</div>
                  </div>
                  {cart.lineItems.map((lineItem) => (
                    <CartItem
                      lineItem={lineItem}
                      key={lineItem.id}
                      cart={cart}
                      client={client}
                    />
                  ))}
                </div>
                <div className={style.totals}>
                  <div className={style.totalsHeader}>Cart Totals</div>
                  <div>
                    <div className={style.totalsLine}>
                      <div>Subtotal</div>
                      <div>${cart.subtotalPrice.amount}</div>
                    </div>
                    <div className={style.totalsLine}>
                      <div>Tax</div>
                      <div className={style.tax}>
                        Tax will be determined at checkout
                      </div>
                    </div>
                    <div className={style.totalsLine}>
                      <div>Shipping</div>
                      <div>
                        <div className={style.shippingAmount}>
                          ${shippingPrice.toFixed(2)}
                        </div>
                        <div className={style.disclaimer}>
                          For orders outside the US, please email{" "}
                          <a href="mailto:carey@careysfinefoods.com">
                            carey@careysfinefoods.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={style.totalsLine}>
                      <div>Total</div>
                      <div>
                        $
                        {(
                          Number.parseFloat(cart.subtotalPrice) + shippingPrice
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.checkoutButtonWrapper}>
                <a
                  href={(cart as any).webUrl}
                  target="_blank"
                  className={style.checkoutButton}
                >
                  Proceed To Checkout
                </a>
              </div>
            </>
          ) : (
            <div className={style.empty}>Your cart is empty</div>
          )}
        </div>
      </Modal>
    </>
  ) : null;
};

const getWeight = (lineItem: any) => {
  if (
    lineItem &&
    lineItem.variant &&
    lineItem.variant.weight &&
    lineItem.quantity
  ) {
    return lineItem.variant.weight * lineItem.quantity;
  } else {
    return 0;
  }
};

CartInternal.displayName = "Product";

export const Cart = connect(
  (state: any) => ({
    client: state.shopify.client,
    cart: state.shopify.cart,
    isOpen: state.shopify.isCartOpen,
  }),
  {}
)(CartInternal);
