import * as React from "react";
import * as style from "./Shop.style";
import * as Components from "../../components";
import { connect } from "react-redux";
import { Product } from "shopify-buy";
import { RootState } from "../../store/reducers";
import { hooks } from "../../utility";

export interface StateProps {
  products: Product[] | null;
}

const ShopInternal: React.FC<StateProps> = ({ products }) => {
  const display = hooks.useDisplayContext();
  return (
    <>
      <div className={style.component}>
        <div className={style.title}>Shop</div>
        <div className={style.subTitleSection}>
          <div className={style.subTitle}>
            <div>Our Favorite Snacks</div>
          </div>
        </div>
        {products && (
          <>
            <div className={style.productBackground} id="product">
              {!display.isMobile && (
                <>
                  <div className={style.plants}>
                    <Components.Icon.Plants
                      stroke="transparent"
                      fill="transparent"
                    />
                  </div>
                  <div className={style.lamp}>
                    <Components.Icon.Lamp
                      stroke="transparent"
                      fill="transparent"
                    />
                  </div>
                </>
              )}
              <div className={style.productContent}>
                {products.map((product) => (
                  <Components.Shopify.Product
                    key={product.id}
                    product={product}
                    className={style.product}
                  />
                ))}
              </div>
            </div>
          </>
        )}

        <div className={style.subTitleSection}>
          <div className={style.subTitle}>
            <div>In Store Locations</div>
          </div>
        </div>
        <div className={style.locationContainer}>
          <div className={style.location}>
            <div className={style.locTitle}>Graul's Market Ruxton</div>
            <div className={style.locAddress}>7713 Bellona Ave</div>
            <div className={style.locAddress}>Ruxton, Maryland 21204</div>
            <div className={style.locPhone}>
              <a href="tel:410-823-6077">(410)-823-6077</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>Graul's Market Mays Chapel</div>
            <div className={style.locAddress}>12200 Tullamore Road</div>
            <div className={style.locAddress}>Lutherville, Maryland 21093</div>
            <div className={style.locPhone}>
              <a href="tel:410-308-6077">(410)-308-6077</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>Eddie's of Roland Park</div>
            <div className={style.locAddress}>6213 N. Charles Street</div>
            <div className={style.locAddress}>Baltimore, Maryland 21212</div>
            <div className={style.locPhone}>
              <a href="tel:410-377-8040">(410)-377-8040</a>
            </div>
          </div>
        </div>
        <div className={style.locationContainer}>
          <div className={style.location}>
            <div className={style.locTitle}>{"Farm & Coast Market"}</div>
            <div className={style.locAddress}>
              7 Bridge Street, Padanaram Village
            </div>
            <div className={style.locAddress}>South Dartmouth, MA 02748</div>
            <div className={style.locPhone}>
              <a href="tel:774-992-7093">(774)-992-7093</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>
              {"Good Earth Market & Restaurant"}
            </div>
            <div className={style.locAddress}>31806 Good Earth Lane</div>
            <div className={style.locAddress}>Ocean View, DE 19970</div>
            <div className={style.locPhone}>
              <a href="tel:302-537-7100">(302)-537-7100</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>
              Good Earth Market Rehoboth Beach
            </div>
            <div className={style.locAddress}>38131 Terrace Road</div>
            <div className={style.locAddress}>Rehoboth Beach, DE 19971</div>
            <div className={style.locPhone}>
              <a href="tel:302-226-3276">(302)-226-3276</a>
            </div>
          </div>
        </div>
        <div className={style.locationContainer}>
          <div className={style.location}>
            <div className={style.locTitle}>{"Eddie’s of Roland Park"}</div>
            <div className={style.locAddress}>5113 Roland Ave</div>
            <div className={style.locAddress}>Baltimore, Maryland 21210</div>
            <div className={style.locPhone}>
              <a href="tel:410-323-3656">(410)-323-3656</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>{"The French Paradox Wines"}</div>
            <div className={style.locAddress}>10435 Stevenson Road</div>
            <div className={style.locAddress}>Stevenson, Maryland 21153</div>
            <div className={style.locPhone}>
              <a href="tel:(410)-413-1630">(410)-413-1630</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>The Spirit Shop</div>
            <div className={style.locAddress}>1407 Clarkview Road</div>
            <div className={style.locAddress}>Baltimore, Maryland 21209</div>
            <div className={style.locPhone}>
              <a href="tel:(410)402-9860">(410)402-9860</a>
            </div>
          </div>
        </div>
        <div className={style.locationContainer}>
          <div className={style.location}>
            <div className={style.locTitle}>{"The Wine Source"}</div>
            <div className={style.locAddress}>3601 Elm Avenue</div>
            <div className={style.locAddress}>Baltimore, Maryland 21211</div>
            <div className={style.locPhone}>
              <a href="tel:(410)467-7777">(410)-467-7777</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>{"Uber Bagels & Deli"}</div>
            <div className={style.locAddress}>49 East Padonia Road</div>
            <div className={style.locAddress}>Timonium, Maryland 21093</div>
            <div className={style.locPhone}>
              <a href="tel:(443)330-5028">(443)-330-5028</a>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.locTitle}>Palmisano's</div>
            <div className={style.locAddress}>13500 Long Green Pike</div>
            <div className={style.locAddress}>Baldwin, Maryland 21013</div>
            <div className={style.locPhone}>
              <a href="tel:(410)592-9477">(410)-592-9477</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ShopInternal.displayName = "Shop";
export const Shop = connect((state: RootState) => {
  return {
    products: state.shopify.products,
  };
}, {})(ShopInternal);
