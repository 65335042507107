import * as Models from "../models";
import { Shop, Cart, Product, ProductVariant } from "shopify-buy";

export enum ActionTypes {
  /**
   * AUTH
   */
  REQUEST_AUTH = "AUTH/REQUEST_AUTH",
  REQUEST_AUTH_SUCCESS = "AUTH/REQUEST_AUTH_SUCCESS",
  REQUEST_AUTH_FAILURE = "AUTH/REQUEST_AUTH_FAILURE",

  LOGIN_STARTED = "SIGNUP_REQUIRED",
  LOGOUT = "LOGOUT",

  /**
   * SHOPIFY
   */

  // CART
  SET_CART_OPEN = "SHOPIFY/SET_CART_OPEN",

  // CLIENT
  CLIENT_CREATED = "SHOPIFY/CLIENT_CREATED",
  PRODUCTS_FETCHED = "SHOPIFY/PRODUCTS_FETCHED ",
  CART_UPDATED = "SHOPIFY/CART_UPDATED",
  SHOP_INFO_FETCHED = "SHOPIFY/SHOP_INFO_FETCHED",
  ADD_TO_CART = "SHOPIFY/ADD_TO_CART",
}

export interface RequestAuth {
  type: ActionTypes.REQUEST_AUTH;
  payload: {
    email: string;
    password: string;
  };
}

export interface RequestAuthSuccess {
  type: ActionTypes.REQUEST_AUTH_SUCCESS;
  payload: {
    uid: string;
    displayName: string;
  };
}

export interface RequestAuthFailure {
  type: ActionTypes.REQUEST_AUTH_FAILURE;
  payload: {
    message?: string;
  };
}

export interface LoginStarted {
  type: ActionTypes.LOGIN_STARTED;
}
export interface Logout {
  type: ActionTypes.LOGOUT;
}

export interface SetCartOpen {
  type: ActionTypes.SET_CART_OPEN;
  payload: {
    isOpen: boolean;
  };
}
export interface ClientCreated {
  type: ActionTypes.CLIENT_CREATED;
  payload: {
    client: any;
  };
}

export interface ShopInfoFetched {
  type: ActionTypes.SHOP_INFO_FETCHED;
  payload: {
    shop: Shop;
  };
}

export interface ProductsFetched {
  type: ActionTypes.PRODUCTS_FETCHED;
  payload: {
    products: Product[];
  };
}

export interface CartUpdated {
  type: ActionTypes.CART_UPDATED;
  payload: {
    cart: Cart;
  };
}

export interface AddToCart {
  type: ActionTypes.ADD_TO_CART;
  payload: {
    id: string | number;
    quantity: number;
    client: any;
    cart: Cart;
  };
}

export type Actions =
  | RequestAuth
  | RequestAuthSuccess
  | RequestAuthFailure
  | LoginStarted
  | Logout
  | SetCartOpen
  | ClientCreated
  | ShopInfoFetched
  | ProductsFetched
  | CartUpdated
  | AddToCart;
