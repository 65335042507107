import { media, style } from "typestyle";
import * as Style from "../../styles";

export const component = style({
  textDecoration: "none",
  fontWeight: 900,
  cursor: "pointer",
  textTransform: "uppercase",
  padding: 20,
  borderRadius: 16,
});
