import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const homeWrapper = s({
  position: "relative",
});

export const homeImage = s(
  {
    marginTop: 85,
    width: "100%",
    height: "90vh",
    objectFit: "cover",
  },
  media(
    { maxWidth: Style.screensize.lgMin },
    {
      marginTop: 80,
    }
  ),
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      marginTop: 70,
    }
  ),
  media(
    { maxWidth: Style.screensize.smMax },
    {
      marginTop: 60,
    }
  )
);

export const homeImageOverlay = s({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  background: `linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(225, 225, 225, 0.1))`,
  zIndex: 2,
});

export const homeContent = s({
  position: "absolute",
  top: 400,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  zIndex: 3,
});

export const orderOnline = s(
  {
    border: "2px solid #BDE863",
    borderRadius: 4,
    color: "#BDE863",
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: Style.text.fontSize.xxxl,
    fontWeight: 200,
    padding: "15px 20px",
    backgroundColor: "transparent",
    cursor: "pointer",
    marginBottom: 200,
    $nest: {
      "&:hover": {
        backgroundColor: Style.colors.primary,
        color: Style.colors.white,
        transition: "0.3s",
      },
    },
    zIndex: 3,
  },
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.xl,
      fontWeight: 400,
    }
  )
);

export const titleSection = s(
  {
    position: "relative",
    padding: 20,
    textAlign: "center",
    fontFamily: Style.text.font.third,
    background:
      "radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 254, 245, 0.690403) 17.19%, #FFFDF1 40.62%)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `60px 100px`,
    }
  )
);

export const clock = s({
  position: "absolute",
  top: 70,
  right: 120,
  width: 150,
  zIndex: 4,
});

export const preHeader = s({
  color: Style.text.color.subtle,
  fontSize: Style.text.fontSize.medium,
  fontFamily: Style.text.font.second,
  marginBottom: 20,
});

export const header = s({
  color: Style.text.color.main,
  fontSize: Style.text.fontSize.xl,
  marginBottom: 25,
});

export const titleContent = s({
  fontSize: Style.text.fontSize.smallMed,
  letterSpacing: 1.2,
});

export const productBackground = s(
  {
    minHeight: 830,
    position: "relative",
    width: "calc(100% + 10px)",
    marginLeft: -10,
    backgroundImage:
      'url("https://ik.imagekit.io/yd113v8urnw4/Carey/green_bg.png?")',
    backgroundSize: "cover",
    zIndex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginTop: -15,
    }
  )
);

export const plants = s({
  position: "absolute",
  left: 20,
  top: -180,
  zIndex: 2,
  height: "100%",
  width: "100%",
});

export const lamp = s({
  width: 120,
  margin: `-60px auto 20px`,
  height: 230,
});

export const productContent = s({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  paddingBottom: 30,
});

export const product = s({
  flex: "0 0 300px",
  marginLeft: 40,
  marginRight: 40,
  marginBottom: 40,
  zIndex: 2,
});

export const reviewsWrapper = s({
  position: "relative",
});

export const reviewBackground = s(
  {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: `30px 20px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: "160px 0px",
      justifyContent: "space-around",
    }
  )
);

export const reviewBackgroundImage = s(
  {
    objectFit: "cover",
    objectPosition: "center",
    zIndex: 1,
    width: "100%",
    minHeight: 1100,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      minHeight: 630,
    }
  )
);

export const reviewBackgroundImageOverlay = s(
  {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: `linear-gradient(to top, rgba(40, 40, 40, 0.20), rgba(225, 225, 225, 0.05))`,
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      background: `linear-gradient(to top, rgba(20, 20, 20, 0.65), rgba(225, 225, 225, 0.1))`,
    }
  )
);

export const reviewComponent = s(
  {
    background: Style.colors.white2,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: 4,
    padding: 30,
    boxShadow: `0 4px 4px rgba(0,0,0,0.25)`,
    fontFamily: Style.text.font.second,
    zIndex: 3,
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: 1,
      height: 300,
      maxWidth: 384,
    }
  )
);

export const reviewTitle = s({
  fontSize: Style.text.fontSize.ml,
  lineHeight: "36px",
  fontWeight: 400,
  marginBottom: 20,
  textAlign: "center",
});

export const rating = s({
  height: 22,
  width: 112,
  marginBottom: 15,
});

export const reviewDetails = s(
  {
    textAlign: "center",
    fontSize: Style.text.fontSize.smallMed,
    color: Style.colors.black3,
    fontWeight: 300,
    marginBottom: 25,
  },
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.small,
      lineHeight: "21px",
    }
  )
);

export const reviewLogo = s({
  height: 65,
  width: 185,
  borderRadius: 2,
  filter: "drop-shadow(0px 4px 4px rgba(0,0,0,.5))",
});
