import * as style from "../../styles";
import { style as s } from "typestyle";

export const component = s({
  $nest: {
    "input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "input[type=number]": { "-moz-appearance": "textfield", margin: 0 },
  },
});
