import { style as s, media } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    marginTop: 85,
    // marginLeft: 100,
    // marginRight: 100,
    paddingTop: 20,
  },
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      marginLeft: 5,
      marginRight: 5,
    }
  )
);

export const title = s(Style.text.extraLargeHeader, {
  paddingLeft: 20,
  paddingBottom: 10,
  color: Style.colors.primary,
  marginBottom: 75,
  borderBottom: `1px solid ${Style.colors.darkTranslucent300}`,
  fontWeight: 300,
  marginLeft: 100,
  marginRight: 100,
  fontFamily: Style.text.font.third,
});

export const subTitleSection = s(
  {
    display: "flex",
    marginTop: 30,
    marginBottom: 30,
    alignItems: "center",
  },
  media(
    { maxWidth: Style.screensize.smMin },
    {
      flexDirection: "column",
    }
  )
);

export const subTitle = s(
  {
    fontFamily: "poppins",
    textAlign: "center",
    fontSize: Style.text.fontSize.xxl,
    fontWeight: 200,
    textTransform: "uppercase",
    color: "rgba(0,0,0,0.6)",
    paddingRight: 15,
    marginBottom: 75,
    flex: 1,
  },
  media(
    { maxWidth: Style.screensize.mdMax },
    {
      fontSize: Style.text.fontSize.xl,
      padding: `15px 10px`,
    }
  ),
  media(
    { maxWidth: Style.screensize.smMin },
    {
      fontSize: Style.text.fontSize.xl,
      paddingBottom: 10,
      borderRight: "none",
      borderBottom: `1px solid ${Style.colors.darkTranslucent100}`,
    }
  )
);

export const subDetails = s(
  {
    fontFamily: "poppins",
    fontSize: Style.text.fontSize.small,
    fontWeight: 300,
    color: Style.colors.black1,
    flex: 1,
    padding: `15px 30px`,
    maxWidth: 1000,
  },
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.small,
      padding: `15px 10px`,
    }
  )
);

export const aboutWrapper = s({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const productBackground = s(
  {
    minHeight: 830,
    paddingTop: 60,
    width: "calc(100% + 10px)",
    position: "relative",
    backgroundImage:
      'url("https://ik.imagekit.io/yd113v8urnw4/Carey/green_bg.png?")',
    marginLeft: -10,
    backgroundSize: "cover",
    zIndex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginTop: -15,
    }
  )
);

export const plants = s({
  position: "absolute",
  left: 20,
  top: -180,
  zIndex: 2,
  height: "100%",
  width: "100%",
});

export const lamp = s({
  width: 120,
  margin: `-60px auto 20px`,
  height: 230,
});

export const productContent = s({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  paddingBottom: 30,
});

export const product = s({
  flex: "0 0 300px",
  marginLeft: 40,
  marginRight: 40,
  marginBottom: 40,
  boxShadow: `0 2px 10px rgba(0,0,0,0.1)`,
});

export const locationContainer = s(
  {
    padding: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      justifyContent: "space-around",
      maxWidth: 1200,
      marginBottom: 10,
      paddingLeft: 120,
      margin: "auto",
    }
  )
);

export const location = s({
  flex: 1,
  marginBottom: 20,
  fontFamily: Style.text.font.third,
});

export const locTitle = s({
  fontSize: Style.text.fontSize.medium,
  fontWeight: 600,
  marginBottom: 10,
});

export const locAddress = s({
  fontSize: Style.text.fontSize.smallMed,
});

export const locPhone = s({
  fontSize: Style.text.fontSize.small,
  marginTop: 5,
  $nest: {
    "& a": {
      color: Style.text.color.subtle,
    },
  },
});
