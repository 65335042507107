import { style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  borderRadius: 4,
  font: Style.text.font.main,
});

export const image = s({
  width: 357,
  borderRadius: 4,
});

export const content = s({
  padding: `10px 0px`,
});

export const title = s({
  fontFamily: Style.text.font.second,
  fontSize: Style.text.fontSize.medium,
  fontWeight: 500,
  lineHeight: "30px",
  paddingBottom: 5,
  borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
  marginBottom: 10,
  textAlign: "center",
  color: "#5C5C5C",
});

export const details = s({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "$464646",
});

export const productDescContainer = s({
  flex: "0 0 100px",
});

export const description = s({
  fontSize: Style.text.fontSize.small,
  fontFamily: Style.text.font.fourth,
  marginBottom: 8,
});

export const price = s({
  fontSize: Style.text.fontSize.medium,
  fontFamily: Style.text.font.second,
  fontWeight: 500,
});

export const quantitySection = s({
  display: "flex",
  alignItems: "center",
});

export const quantityInput = s({
  flex: 1,
  marginRight: 5,
});

export const quantityButtons = s({
  width: 30,
  marginRight: 20,
});

export const button = s({
  cursor: "pointer",
  width: 200,
  padding: 5,
  fontFamily: Style.text.font.fifth,
  fontSize: Style.text.fontSize.smallMed,
  border: `.5px solid ${Style.colors.black2}`,
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: ".3s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent650,
      color: Style.text.color.inverse,
    },
  },
});
