import * as React from "react";
import * as style from "./Home.style";
import * as Components from "../../components";
import { connect } from "react-redux";
import { Product } from "shopify-buy";
import { RootState } from "../../store/reducers";
import scrollToElement from "scroll-to-element";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";

export interface StateProps {
  products: Product[] | null;
}

const HomeInternal: React.FC<StateProps> = ({ products }) => {
  const scrollToProduct = () => {
    const productElement = document.getElementById("product");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -50,
      });
    }
  };
  const display = hooks.useDisplayContext();
  console.log(products);

  return (
    <>
      <div>
        <div className={style.homeWrapper}>
          <IKImage
            path={`/home-pretzels.jpg`}
            alt="pretzels spread"
            className={style.homeImage}
          />
          <div className={style.homeImageOverlay} />
          <div className={style.homeContent}>
            <button onClick={scrollToProduct} className={style.orderOnline}>
              Order Online
            </button>
          </div>
        </div>

        <div className={style.titleSection}>
          <div className={style.preHeader}>A cocktail snack so tasty...</div>
          <div className={style.header}>Carey's Signature Pretzels</div>
          <div className={style.titleContent}>
            Pretzel bites drenched in bourbon, butter and spices, you cannot eat
            just one!
          </div>
          {!display.isMobile && (
            <div className={style.clock}>
              <Components.Icon.Clock stroke="transparent" fill="transparent" />
            </div>
          )}
        </div>
        {products && (
          <>
            <div className={style.productBackground} id="product">
              {!display.isMobile && (
                <>
                  <div className={style.plants}>
                    <Components.Icon.Plants
                      stroke="transparent"
                      fill="transparent"
                    />
                  </div>
                  <div className={style.lamp}>
                    <Components.Icon.Lamp
                      stroke="transparent"
                      fill="transparent"
                    />
                  </div>
                </>
              )}
              <div className={style.productContent}>
                {products.map((product) => (
                  <Components.Shopify.Product
                    key={product.id}
                    product={product}
                    className={style.product}
                  />
                ))}
              </div>
            </div>
          </>
        )}
        <div className={style.reviewsWrapper}>
          <IKImage
            path={"/gobstopper.png"}
            alt="pretzels"
            className={style.reviewBackgroundImage}
            loading="lazy"
          />
          <div className={style.reviewBackgroundImageOverlay} />
          <div className={style.reviewBackground}>
            <div className={style.reviewComponent}>
              <div className={style.reviewTitle}>"Simply Addictive!"</div>
              <div className={style.rating}>
                <IKImage
                  path={`/5star.jpg`}
                  alt="5 star"
                  loading="lazy"
                  className={style.rating}
                />
              </div>
              <div className={style.reviewDetails}>
                “These pretzels are simply addictive! They are the perfect bar
                snack with just the right mix of sweet and heat. Absolutely
                delicious!”
              </div>
              <IKImage
                path={`/logo.png`}
                alt="logo"
                loading="lazy"
                className={style.reviewLogo}
              />
            </div>
            <div className={style.reviewComponent}>
              <div className={style.reviewTitle}>"Obsessed"</div>
              <div className={style.rating}>
                <IKImage
                  path={`/5star.jpg`}
                  alt="5 star"
                  loading="lazy"
                  className={style.rating}
                />
              </div>
              <div className={style.reviewDetails}>
                “Carey’s food is always delicious and her staff is wonderful!
                Order some of her incredible pretzels... I'm obsessed!”
              </div>
              <IKImage
                path={`/logo.png`}
                alt="logo"
                loading="lazy"
                className={style.reviewLogo}
              />
            </div>
            <div className={style.reviewComponent}>
              <div className={style.reviewTitle}>"The Best!"</div>
              <div className={style.rating}>
                <IKImage
                  path={`/5star.jpg`}
                  alt="5 star"
                  loading="lazy"
                  className={style.rating}
                />
              </div>
              <div className={style.reviewDetails}>
                “They are the BEST! Everyone in our office loves them.”
              </div>
              <IKImage
                path={`/logo.png`}
                alt="logo"
                loading="lazy"
                className={style.reviewLogo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HomeInternal.displayName = "Home";
export const Home = connect((state: RootState) => {
  return {
    products: state.shopify.products,
  };
}, {})(HomeInternal);
