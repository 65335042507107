import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    position: "fixed",
    padding: `0px 20px`,
    top: 0,
    left: 0,
    right: 0,
    background: Style.colors.translucent900,
    color: Style.colors.white,
    zIndex: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      padding: 10,
    }
  )
);

export const logo = s(
  {
    width: "100%",
    height: 140,
    // backgroundColor: Style.colors.black3,
    textAlign: "center",
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      height: 100,
    }
  )
);

export const logoImage = s(
  {
    height: 150,
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      height: 100,
    }
  )
);

export const desktopContent = s(
  {
    display: "flex",
    top: 0,
    justifyContent: "space-around",
    width: "30%",
    marginRight: 30,
    zIndex: 5,
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      display: "none",
    }
  )
);

export const menuButton = s({
  color: Style.colors.black3,
  $nest: {
    "&:hover": {
      background: "transparent",
      borderRadius: 8,
      color: Style.colors.primary,
      transition: "0.3s",
    },
  },
});

export const menuText = s({
  paddingLeft: 10,
  textTransform: "uppercase",
});

export const desktopContentWLogo = s({
  display: "flex",
  justifyContent: "Space-between",
});

export const mobileContent = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "none",
    }
  )
);

export const left = s({
  display: "flex",
  alignItems: "center",
  flex: 1,
});

export const right = s({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
  $nest: {
    "&>*": {
      marginRight: 20,
    },
  },
});

export const header = s(
  Style.text.largeHeader,
  media({ maxWidth: Style.screensize.xsMax }, { display: "none" })
);

export const link = s({
  textDecoration: "none",
  color: Style.colors.black1,
});

export const nav = s({
  backgroundColor: "transparent",
  boxShadow: "none",
  // borderBottom: `3px solid ${Style.colors.black3}`,
  marginBottom: "1rem",
  fontFamily: Style.text.font.main,
  textShadow: ".5px .5px 1px rgba(36,36,36,0.5)",
  flexGrow: 1,
  padding: "1rem 1rem",
  paddingBottom: "1rem",
  color: Style.colors.palletPurple,
  fontSize: "1.15rem",
});

export const modalStyles = {
  content: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: Style.colors.primary,
    opacity: 0.8,
  },
  overlay: {
    zIndex: 1000,
  },
};

export const cancelIcon = s({
  color: Style.colors.white,
  fontSize: Style.text.fontSize.xxl,
  $nest: {
    "&:hover": {
      color: Style.colors.primary,
      transition: "0.3s",
    },
  },
});

export const menuItem = s(
  {
    marginBottom: 10,
    width: "100%",
    textAlign: "center",
    fontSize: Style.text.fontSize.xxl,
    fontFamily: Style.text.font.third,
    padding: 5,
    borderRadius: 10,
    $nest: {
      "& a": {
        color: Style.text.color.inverse,
        $nest: {
          "&:hover": {
            color: Style.colors.black1,
            transition: "0.3s",
          },
        },
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 40,
      fontSize: 120,
    }
  )
);

export const mobileButton = s({
  width: "100%",
});

export const more = s({
  display: "flex",
  alignItems: "center",
  flex: 1,
  height: "100%",
  marginRight: 30,
});

export const cart = s(
  {
    position: "relative",
    $nest: {
      "& svg": {
        fontSize: 30,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      $nest: {
        "& svg": {
          fontSize: 40,
        },
      },
    }
  )
);

export const cartCount = s(
  {
    fontFamily: Style.text.font.third,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: Style.colors.white,
    fontWeight: 600,
    backgroundColor: Style.colors.primary,
    right: 2,
    top: 4,
    fontSize: 12,
    height: 18,
    width: 18,
    borderRadius: 16,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      right: -4,
      top: -4,
      fontSize: 16,
      height: 24,
      width: 24,
      borderRadius: 16,
    }
  )
);
