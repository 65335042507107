export const white = "#fff";
export const white2 = "rgba(255, 253, 241, 0.96)";

export const translucent900 = "rgba(255, 255, 255, 0.95)";
export const translucent650 = "rgba(255, 255, 255, 0.65)";
export const translucent400 = "rgba(255, 255, 255, 0.4)";
export const translucent150 = "rgba(255, 255, 255, 0.15)";

export const darkTranslucent20 = "rgba(0, 0, 0, 0.02)";
export const darkTranslucent30 = "rgba(0, 0, 0, 0.03)";
export const darkTranslucent100 = "rgba(0, 0, 0, 0.1)";
export const darkTranslucent200 = "rgba(0, 0, 0, 0.2)";
export const darkTranslucent300 = "rgba(0, 0, 0, 0.3)";
export const darkTranslucent650 = "rgba(0, 0, 0, 0.75)";

export const shade200 = "#9B9B9B";
export const shade400 = "#535353";
export const shade600 = "#333";
export const shade700 = "#242424";

export const primary = "#BDE863";
export const secondary = "#517517";

export const black1 = "#000";
export const black2 = "#323232";
export const black3 = "#2D283E";

export const background = "#0A0A0A";

export enum gradientOption {
  purpToPurp = "purpToPurp",
  purpToTransparent = "purpToTransparent",
  transparentToPurp = "transparentToPurp",
}

export const palletPurple = "#A64AC9";
export const palletPink = "#E63C8E";
export const palletYellow = "#FCCD04";
export const palletSalmon = "#F5B48F";
export const palletTan = "#F5E6CC";
export const palletTeal = "#d9fffc";
export const palletFlatTeal = "#739DA5";
export const palletOrange = "rgba(255, 135, 15)";

/**
 * GRADIENTS
 * Colors used to create gradients
 */
export enum gradient {
  shallow = "#bd86d9",
  deep = "#8928ba",
  lighterdeep = "#b04de3",
}

export const createGradient = (
  name: keyof typeof gradientOption,
  direction: "to right" | "to left" | "to bottom" | "to top" = "to bottom",
  colorStop1?: "50%" | "80%"
) => {
  switch (name) {
    case gradientOption.purpToPurp:
      return `linear-gradient(${direction}, ${gradient.deep} ${
        colorStop1 ? colorStop1 : ""
      }, ${gradient.shallow})`;
    case gradientOption.purpToTransparent:
      return `linear-gradient(${direction}, ${gradient.shallow} ${
        colorStop1 ? colorStop1 : ""
      }, transparent)`;
    case gradientOption.transparentToPurp:
      return `linear-gradient(${direction}, transparent ${
        colorStop1 ? colorStop1 : ""
      }, ${gradient.shallow})`;
  }
};
