import * as React from "react";

import { Link } from "../Link";
import * as style from "./Header.style";
import { IconButton } from "../../components/IconButton";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

export interface ComponentProps {
  onClose: () => void;
}
/**
 * Header component
 * Page Header
 */

const MobileHeader: React.FC<ComponentProps> = ({ onClose }) => {
  return (
    <>
      <div onClick={onClose}>
        <IconButton size="small" onClick={onClose}>
          <CancelRoundedIcon className={style.cancelIcon} />
        </IconButton>
        <div className={style.menuItem}>
          <Link to={`/`}>Home</Link>
        </div>
        <div className={style.menuItem}>
          <Link to={`/about`}>About Us</Link>
        </div>
        <div className={style.menuItem}>
          <Link to={`/shop`}>Shop</Link>
        </div>
      </div>
    </>
  );
};

MobileHeader.displayName = "MobileHeader";

export { MobileHeader };
