import ClockSVG from "./svgs/clock.svg";
import LampSVG from "./svgs/lamp.svg";
import PlantsSVG from "./svgs/plants.svg";
import BracketsSVG from "./svgs/brackets.svg";
import { Icon } from "./Icon";

export const Clock = Icon(ClockSVG);
export const Lamp = Icon(LampSVG);
export const Plants = Icon(PlantsSVG);
export const Brackets = Icon(BracketsSVG);
