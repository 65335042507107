import { media, style as s, style } from "typestyle";
import { NestedCSSSelectors } from "typestyle/lib/types";
import * as Style from "../../styles";

const family = "poppins";

export const component = s(
  {
    backgroundColor: Style.colors.darkTranslucent30,
    padding: `0 20px`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `0 50px`,
    }
  )
);

export const mainFooter = s(
  {
    padding: `20px 0`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `50px 0`,
      display: "flex",
      justifyContent: "flex-start",
    }
  )
);

export const content = s(
  {
    color: Style.colors.darkTranslucent650,
    fontSize: 14,
    lineHeight: 1.1,
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: 1,
      lineHeight: 1.2,
      fontSize: 18,
      paddingLeft: 20,
    }
  )
);

export const socialContent = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const logoWrapper = s({
  flex: 2,
  display: "flex",
  paddingTop: 20,
});

export const logo = s(
  {
    width: 490,
    height: 180,
    borderRadius: 4,
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      display: "none",
    }
  )
);

export const header = s(
  {
    textTransform: "uppercase",
    fontSize: Style.text.fontSize.large,
    marginBottom: 20,
    color: Style.colors.black2,
    fontFamily: family,
    fontWeight: 400,
  },
  media({ minWidth: Style.screensize.mdMin }, {})
);

export const locationInfo = s({
  fontFamily: family,
  fontWeight: 200,
  paddingBottom: 10,
});

export const iconContainer = s({
  display: "flex",
  justifyContent: "flex-start",
  // marginLeft: 35,
});

export const socialmediaIcons = s(
  {
    color: Style.colors.black2,
    fontSize: Style.text.fontSize.xxl,
    margin: "0 10px 10px 10px",
    $nest: {
      "&:hover": {
        color: Style.colors.primary,
        transition: "0.3s",
      },
    },
  },
  media(
    { maxWidth: Style.screensize.mdMax },
    {
      margin: 5,
    }
  )
);
export const secondFooter = s({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: Style.text.fontSize.small,
  padding: 20,
  borderTop: `${Style.colors.darkTranslucent100} 2px solid`,
  color: Style.colors.darkTranslucent650,
});

export const copyright = s({
  marginBottom: 10,
  fontSize: Style.text.fontSize.small,
});

export const allCapsWrapper = s({
  fontSize: Style.text.fontSize.smallMed,
  fontFamily: Style.text.font.second,
  fontWeight: 800,
  letterSpacing: "2px",
  cursor: "pointer",

  padding: `5px 10px`,
  borderRadius: 16,
  $nest: {
    "&>a": {
      transition: ".2s ease-in",
      color: "inherit",
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      $nest: {
        "&>div>svg": {
          transition: ".2s ease-in",
        },
        "&:hover": {
          color: Style.text.color.subtle,
          $nest: {
            "&>div>svg": {
              fill: Style.text.color.subtle,
              stroke: Style.text.color.subtle,
            },
          },
        },
      },
    },
  },
});
