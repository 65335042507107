import Client from "shopify-buy";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../store/actions";

export const ShopifyStartup: React.FC = () => {
  const dispatch = useDispatch();
  try {
    const client = (Client as any).buildClient({
      storefrontAccessToken: "95bb5ee7179639790a33462a232c4c71",
      domain: "careys-fine-foods.myshopify.com",
      // apiVersion: "2.18.0",
    });
    dispatch({
      type: ActionTypes.CLIENT_CREATED,
      payload: { client },
    });

    client.product.fetchAll().then((products) => {
      dispatch({
        type: ActionTypes.PRODUCTS_FETCHED,
        payload: {
          products,
        },
      });
    });

    client.checkout.create().then((cart) => {
      dispatch({
        type: ActionTypes.CART_UPDATED,
        payload: {
          cart,
        },
      });
    });

    client.shop.fetchInfo().then((shop) => {
      dispatch({
        type: ActionTypes.SHOP_INFO_FETCHED,
        payload: {
          shop,
        },
      });
    });
  } catch (error) {
    console.log(error);
  }
  return null;
};
