import { takeLatest, call, put, getContext } from "redux-saga/effects";
import { ActionTypes, AddToCart } from "../actions";

export function* takeAddToCart(action: AddToCart) {
  const { id, quantity, cart, client } = action.payload;
  const lineItemsToAdd = [{ variantId: id, quantity }];
  console.log(id, quantity, cart, client);
  if (cart && client) {
    const newCart = yield call(
      client.checkout.addLineItems,
      cart.id,
      lineItemsToAdd
    );
    console.log(newCart);
    // yield put({ type: ActionTypes.CART_UPDATED, payload: { cart: newCart } });
  }
}

export function* takeAddToCartSaga() {
  yield takeLatest(ActionTypes.ADD_TO_CART, takeAddToCart);
}
