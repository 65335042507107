import * as React from "react";
import * as style from "./Footer.style";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import { Icon } from "../../components";
import { IKImage } from "imagekitio-react";
import { classes } from "typestyle";

const Footer: React.FC = () => {
  return (
    <>
      <div></div>
      <div className={style.component}>
        <div className={style.mainFooter}>
          <div className={style.logoWrapper}>
            <IKImage
              path={`/logo.png`}
              alt="logo"
              className={style.logo}
              loading="lazy"
            />
          </div>
          <div className={style.content}>
            <div className={style.header}>Location</div>
            <div className={style.locationInfo}>
              8600 Lasalle Road, Suite 501
            </div>
            <div className={style.locationInfo}>Towson, Maryland 21286</div>
          </div>
          <div className={classes(style.socialContent, style.content)}>
            <div>
              <div className={style.header}>Being Social</div>
              <div className={style.iconContainer}>
                <a
                  href="https://www.facebook.com/CareysFineFoods"
                  target="_blank"
                >
                  <FacebookIcon className={style.socialmediaIcons} />
                </a>
                <a
                  href="https://www.instagram.com/taluccifinefoods/"
                  target="_blank"
                >
                  <InstagramIcon className={style.socialmediaIcons} />
                </a>
                <a href="mailto:Carey@careysfinefoods.com" type="email">
                  <EmailIcon className={style.socialmediaIcons} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={style.secondFooter}>
          <div className={style.copyright}>
            &copy; 2021 Talucci Fine Foods. All Rights Reserved{" "}
          </div>
          <div className={style.allCapsWrapper}>
            <a href="https://www.allcapstechnology.com/" target="_blank">
              <Icon.Brackets size={20} />
              <div style={{ paddingLeft: 10 }}>Powered By ALL CAPS</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.displayName = "Footer";
export { Footer };
