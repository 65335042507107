import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  // position: "relative",
  width: "100%",
  height: 800,
  backgroundColor: Style.colors.darkTranslucent30,
});

export const map = s({});
