import { Actions, ActionTypes } from "../actions";
import { Client, Cart, Product, ProductVariant, Shop } from "shopify-buy";

export interface ShopifyStore {
  isCartOpen: boolean;
  client: Client | null;
  cart: Cart | null;
  products: Product[] | null;
  shop: Shop | null;
}

export const initialState: ShopifyStore = {
  isCartOpen: false,
  client: null,
  cart: null,
  products: null,
  shop: null,
};

export const shopify = (
  state: ShopifyStore = initialState,
  action: Actions
): ShopifyStore => {
  switch (action.type) {
    case ActionTypes.SET_CART_OPEN:
      return {
        ...state,
        isCartOpen: action.payload.isOpen,
      };
    case ActionTypes.CLIENT_CREATED:
      return { ...state, client: action.payload.client };
    case ActionTypes.PRODUCTS_FETCHED:
      return { ...state, products: action.payload.products };
    case ActionTypes.CART_UPDATED:
      return { ...state, cart: action.payload.cart };
    case ActionTypes.SHOP_INFO_FETCHED:
      return { ...state, shop: action.payload.shop };
    default: {
      return state;
    }
  }
};
