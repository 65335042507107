import * as Style from "../../styles";
import { style as s } from "typestyle";

export const component = s({
  border: `1px solid ${Style.colors.shade400}`,
  borderRadius: 10,
  marginBottom: 20,
  $nest: {
    "&>*": {
      borderBottom: `1px solid ${Style.colors.shade400}`,
      $nest: {
        "&:nth-of-type(2n)": {
          backgroundColor: Style.colors.darkTranslucent30,
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
});
