import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
  wrapperClassName?: string;
  value: Date | null;
  label: string;
  onChange: (date: any) => void;
}
/**
 * DatePicker component
 * Standard MUI Date Picker Wrapper
 */
const DatePicker: React.FC<ComponentProps> = ({
  wrapperClassName,
  className,
  value,
  label,
  onChange,
}) => {
  return (
    <div className={wrapperClassName}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          autoOk={true}
          label={label}
          value={value}
          onChange={onChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePicker.displayName = "DatePicker";

export { DatePicker };
