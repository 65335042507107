import { createBrowserHistory } from "history";
import * as React from "react";
import { Route, Router } from "react-router-dom";
import { Header, Footer, Shopify } from "../components";
import { Home, About, Shop } from "../pages";
import { ShopifyStartup } from "./ShopifyStartup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { style as s } from "typestyle";
import { useSpring, animated } from "react-spring";
import { IKContext } from "imagekitio-react";

const component = s({
  height: "100%",
  zIndex: 1,
  overflow: "hidden",
  margin: -8,
});

const Routes = () => {
  return (
    <div>
      <Route strict exact={true} path="/" component={Home} />
      <Route strict exact={true} path="/about" component={About} />
      <Route strict exact={true} path="/shop" component={Shop} />
      <div style={{ display: "none" }}>
        Carey's Fine Foods selling gourmet pretzels and other bar snacks
      </div>
    </div>
  );
};

const history = createBrowserHistory();

export const App = () => {
  const spring = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });
  return (
    <animated.div
      style={{ ...spring, position: "relative" }}
      className={component}
    >
      <Router history={history}>
        <IKContext urlEndpoint="https://ik.imagekit.io/yd113v8urnw4/Carey">
          <Header />
          <Routes />
          <Footer />
        </IKContext>
        <ShopifyStartup />
        <Shopify.Cart />
        <ToastContainer
          autoClose={2000}
          position="top-right"
          newestOnTop={false}
          hideProgressBar={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </Router>
    </animated.div>
  );
};
