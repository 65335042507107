import React from "react";
import { colors } from "../../styles";

interface ComponentProps {
  fill?: string;
  stroke?: string;
  height?: number;
  width?: number;
  size?: number;
  onClick?: () => void;
}

export const Icon: (Icon: any) => React.FC<ComponentProps> =
  (Icon: any) =>
  ({ fill, stroke, height, width, size, onClick }) => {
    return (
      <div
        style={{
          height: height || size || "100%",
          width: width || size || "100%",
        }}
      >
        <Icon
          height={height || size || "100%"}
          width={width || size || "100%"}
          fill={fill || colors.darkTranslucent650}
          stroke={stroke || colors.darkTranslucent650}
          onClick={onClick}
        />
      </div>
    );
  };
